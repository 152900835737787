import "bootstrap";
import $ from "jquery";


import "../plugins/solidnav.js";
import "../plugins/caroussel.js";
import "../plugins/play-yt.js";
import "../plugins/btn_scrollup.js";
import "../plugins/preloader.js";
import "../plugins/captation-mail.js";
import "../plugins/popup-promo.js";
import "../plugins/btn-capture-mail.js";

import { formspree } from "plugins/formspree.js";
import { playyt } from "plugins/play-yt.js";
import { map } from "plugins/map.js";

formspree();
playyt();
map();



// console.log('.form-inputs')
